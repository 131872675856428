
export function register(config) {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then((registration) => {
                    // console.log('Service Worker registered:', registration);
                })
                .catch((error) => {
                    // console.error('Error registering Service Worker:', error);
                });
        });
    }
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
            })
            .catch((error) => {
                // console.error('Error unregistering Service Worker:', error);
            });
    }
}



function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

// checks if the localStorage item has been set
function getVisited() {
    return getWithExpiry('pwa-install-prompt-dap-web-app')
    // return localStorage.getItem('install-prompt');
}


let installPromptEvent;

function installPWA() {
    if (installPromptEvent) {
        installPromptEvent.prompt();

        installPromptEvent.userChoice
            .then((choiceResult) => {
                if(choiceResult.outcome === 'accepted') {
                    console.log('User Accepted');

                    const pwaInstallPromptBtn = document.querySelector(".pwa-install-prompt-btn");
                    // pwaInstallPromptBtn?.style.display = '';
                    pwaInstallPromptBtn?.classList.remove("show-btn");
                    pwaInstallPromptBtn?.classList.add("d-none");
                    
                } else {
                    console.log('User dismissed');
                }

                installPromptEvent = null;
            })
    }
}

window.addEventListener("beforeinstallprompt", (e) => {
    e.preventDefault();

    setTimeout(() => {
        // install button fast preview (only for login page)
        const pwaInstallPromptBtn = document.querySelector(".pwa-install-prompt-btn");
        if (!getVisited()) {
            if (pwaInstallPromptBtn) {
                pwaInstallPromptBtn?.classList.remove("d-none");
            }
        }
    }, 100);
    
    setTimeout(() => {
        const pwaInstallPromptBtn = document.querySelector(".pwa-install-prompt-btn");

        // if no localStorage is set, first time visitor
        if (!getVisited()) {
            if (pwaInstallPromptBtn) {
                pwaInstallPromptBtn.style.display = '';
                pwaInstallPromptBtn?.classList.remove("d-none");
                setTimeout(() => {
                    pwaInstallPromptBtn?.classList.add("show-btn");
                    pwaInstallPromptBtn?.classList.add("show-text");
                }, 50);
            }
        }
        else {
            // customPromptElement.style.display = '';
        }

        installPromptEvent = e;

        pwaInstallPromptBtn?.addEventListener("click", (e) => {
            e.preventDefault();
            installPWA();
        });
    }, 2000);
});

window.addEventListener('load', () => {
    // // Because the 'LayoutAndHeader' component is loaded as a child of 'AuthenticationHandler', the PWA install button is not loaded immediately after the window is loaded. So the following content wont works!
    // const pwaInstallPromptBtn = document.querySelector(".pwa-install-prompt-btn");
    // pwaInstallPromptBtn?.addEventListener("click", (e) => {
    //     e.preventDefault();
    //     installPWA();
    // });
});

