import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedSellerInOpenPaymentModal, changeSellersOpenPaymentDataList, toggleOpenPaymentsModal } from "../../store/slices/paymentManagementSlice";

import './Payment.scss';
import { useTranslation } from "react-i18next";
import CustomModal from "../customModal";
import useSellerDetails from "../useSellerDetails";

import ReactEcharts from "echarts-for-react"; 
import React, { useEffect, useRef } from "react";
import Select from 'react-select';
import { fetchOpenPayDetails, fetchOpenPayDetailsForAllSellers } from "../../helpers/apiUtilities";
import PaymentStatisticsPlaceholder from "../Layouts/PaymentStatisticsPlaceholder";


const OpenPaymentsOfOneSeller = (props) => {
    const {
        openPaymentsData,
    } = props;

    const {
        details: openPayments_detailsList,
        sellerId,
        sellerName
    } = openPaymentsData || {};

    const { i18n, t } = useTranslation();

    const {sellerDetails} = useSellerDetails();

    const {
        sellers: sellersList,
        currency_symbol
    } = sellerDetails || {};

    const chartWrapperRefs = useRef([]);


    // const sellerName_capitalized = typeof sellerName === "string" && sellerName.split(" ").map(name_part => {
    //     return name_part[0].toUpperCase() + name_part.slice(1)
    // }).join(" ") || sellerName || "";


    const chartGeneralOption = {
        title: {
            // text: `${sellerName_capitalized} ${t("Open_Payments")}`,
            left: 'center',
            subtextStyle: {
                color: '#999',
                fontWeight: 'normal',
                fontSize: 14
            }
        },
        tooltip: {
            trigger: 'item',
            // formatter: '{a} <br/>{b} : {c} ({d}%)',
            // formatter: '{a} <br/><strong style="color: red; font-size: 20px; vertical-align: middle; margin-top: -3px;">&bull;</strong> {b} : {c} ({d}%)',
            formatter: function(params) {
                var tooltipContent =
                    `<span>${params.seriesName}</span>
                    <br/>
                    <div style="display: flex; justify-content: space-between;">
                        <span style="margin-right: 20px;">${params.marker} ${params.name} (${currency_symbol})</span>
                        <span>${params.value} (${params.percent}%)</span>
                    </div>`;    
                return tooltipContent;
            },
        },
        legend: {
            bottom: 0,
            left: 'center',
            // orient: "vertical",
            // top: 'center',
            // left: 0,
            // data: []
        },
        // series: []
    };
    const chartGeneralOptionSerieItem = {
        type: 'pie',
        // name: '',
        // height: '72%',
        height: '82%',
        // top: 50,
        top: 'center',
        // center: ['50%', '50%'],
        center: ['65%', '50%'],
        selectedMode: 'single',
        // radius: ['38%', '70%'],
        itemStyle: {
            borderRadius: 6,
            borderColor: '#fff',
            borderWidth: 5,
        },
        emphasis: {
            label: {
                show: true,
                fontSize: 15,
                fontWeight: 'bold'
            }
        },
        // data: []
    };


    const getCorrectNumber = (num) => {
        const correctNumber = +num.toString().replaceAll(",", "");
        return correctNumber;
    }


    return (
        <div className="mt-5">
            {sellersList?.length ? (
                <div className="d-flex align-items-center mx-3">
                    <span className="flex-grow-1">
                        <label className="seller-item-sec-heading-label text-capitalize mb-0">
                            {t("seller")}: &nbsp;
                        </label>
                        <span className="seller-item-sec-title text-capitalize fw-bold">
                            {sellerName}
                        </span>
                    </span>
                </div>
            ) : (
                <></>
            )}

            <div className="overflow-auto custom-scrollbar mx-3">
                <table className="table open-payments-table table-bordered table-rounded text-center mt-4 mx-auto">
                    <thead className="table-light text-capitalize">
                        <tr>
                            <th>{t("paymentType")}</th>
                            <th>{t("ordersPrice")} {currency_symbol}</th>
                            <th>{t("tip")} {currency_symbol}</th>
                            <th>{t("totalAmount")} {currency_symbol}</th>
                            <th>{t("discount")} {currency_symbol}</th>
                            <th>{t("paidPrice")} {currency_symbol}</th>
                            <th>{t("fromTime")}</th>
                            <th>{t("toTime")}</th>
                        </tr>
                    </thead>
                    <tbody className="fs-4 fw-lighter">
                        {openPayments_detailsList?.length && openPayments_detailsList.map((pay_details, index) => {
                            const {
                                // source_type,
                                amount,
                                discount_price,
                                paid_price,
                                orders_price,
                                tip_price,
                                from_time,
                                to_time,
                                pay_type
                            } = pay_details || [];

                            const fromTime = new Date(from_time).toLocaleString();
                            const toTime = new Date(to_time).toLocaleString();

                            return (
                                <tr
                                    // key={index}
                                    key={pay_type}
                                >
                                    <td>{pay_type}</td>
                                    <td>{orders_price}</td>
                                    <td>{tip_price}</td>
                                    <td>{amount}</td>
                                    <td>{discount_price}</td>
                                    <td>{paid_price}</td>
                                    <td>{fromTime}</td>
                                    <td>{toTime}</td>
                                </tr>
                            )
                        }) || (
                            <tr></tr>
                        )}
                    </tbody>
                </table>
            </div>

            <div className="charts-wrapper d-flex flex-wrap justify-content-center">
                {openPayments_detailsList?.length && openPayments_detailsList.map((pay_details, index) => {
                    const {
                        // source_type,
                        // amount,
                        discount_price,
                        paid_price,
                        orders_price,
                        tip_price,
                        // from_time,
                        // to_time,
                        pay_type
                    } = pay_details || [];

                    const chrtOpt = {
                        ...chartGeneralOption,
                        title: {
                            ...chartGeneralOption?.title,
                            text: `${pay_type} ${t("payments")}`,
                            // subtext: `${t("paidBy")}: ${pay_type}`
                        },
                        legend: {
                            ...chartGeneralOption?.legend,
                            data: [t("paidPrice"), t("ordersPrice"), t("discount"), t("tip")]
                        },
                        series: [
                            {
                                ...chartGeneralOptionSerieItem,
                                // name: t("paymentsWithoutDiscount"),
                                // name: t("paymentsExcludingDiscounts"),
                                name: t("paymentsRegardlessOfTheirDiscount"),
                                radius: ['35%', '65%'],
                                height: '50%',
                                center: ['25%', '50%'],
                                // label: {
                                //     position: 'inner',
                                //     fontSize: 14
                                // },
                                // labelLine: {
                                //     show: false
                                // },
                                // itemStyle: {
                                //     ...chartGeneralOptionSerieItem.itemStyle,
                                //     borderRadius: 3,
                                //     borderWidth: 2
                                // },
                                label: {
                                    // alignTo: 'edge',
                                    formatter: `{name|{b}}\n{value|{c} ${currency_symbol}  ({d}%)}`,
                                    minMargin: 5,
                                    edgeDistance: 10,
                                    lineHeight: 15,
                                    rich: {
                                        value: {
                                            fontSize: 11,
                                            fontWeight: 600,
                                            color: '#929292'
                                        }
                                    }
                                },
                                labelLine: {
                                    length: 15,
                                    length2: 5,
                                    maxSurfaceAngle: 80
                                },
                                // avoidLabelOverlap: false,
                                labelLayout: function (params) {
                                    const chartWrapperElem = chartWrapperRefs?.current[index];
                                    if (chartWrapperElem) {
                                        const chartWidth = chartWrapperElem.getBoundingClientRect().width;
                                        const isLeft = params.labelRect.x < (chartWidth * 0.25);

                                        const points = params.labelLinePoints;
                                        // Update the end point.
                                        points[2][0] = isLeft
                                            ? params.labelRect.x
                                            : params.labelRect.x + params.labelRect.width;

                                        return {
                                            labelLinePoints: points
                                        };
                                    }
                                },
                                data: [
                                    {
                                        name: t("ordersPrice"),
                                        value: getCorrectNumber(orders_price)
                                    },
                                    {
                                        name: t("tip"),
                                        value: getCorrectNumber(tip_price)
                                    }
                                ]
                            },
                            {
                                ...chartGeneralOptionSerieItem,
                                // name: t("discountedPayments"),
                                // name: t("paymentsIncludingDiscounts"),
                                name: t("paymentsTakingIntoAccountTheirDiscount"),
                                radius: ['35%', '65%'],
                                height: '70%',
                                center: ['72%', '50%'],
                                label: {
                                    // alignTo: 'edge',
                                    formatter: `{name|{b}}\n{value|{c} ${currency_symbol}  ({d}%)}`,
                                    minMargin: 5,
                                    edgeDistance: 10,
                                    lineHeight: 15,
                                    rich: {
                                        value: {
                                            fontSize: 11,
                                            fontWeight: 600,
                                            color: '#929292'
                                        }
                                    }
                                },
                                labelLine: {
                                    length: 15,
                                    length2: 5,
                                    maxSurfaceAngle: 80
                                },
                                // avoidLabelOverlap: false,
                                labelLayout: function (params) {
                                    const chartWrapperElem = chartWrapperRefs?.current[index];
                                    if (chartWrapperElem) {
                                        const chartWidth = chartWrapperElem.getBoundingClientRect().width;
                                        const isLeft = params.labelRect.x < (chartWidth * 0.7);

                                        const points = params.labelLinePoints;
                                        // Update the end point.
                                        points[2][0] = isLeft
                                            ? params.labelRect.x
                                            : params.labelRect.x + params.labelRect.width;

                                        return {
                                            labelLinePoints: points
                                        };
                                    }
                                },
                                data: [
                                    {
                                        name: t("paidPrice"),
                                        value: getCorrectNumber(paid_price)
                                    },
                                    {
                                        name: t("discount"),
                                        value: getCorrectNumber(discount_price)
                                    },
                                    {
                                        name: t("tip"),
                                        value: getCorrectNumber(tip_price)
                                    }
                                ]
                            }
                        ]
                    };

                    return (
                        <div
                            key={pay_type}
                            ref={elem => chartWrapperRefs.current[index] = elem}
                            className="chart-item-wrapper px-3 mt-4"
                        >
                            <ReactEcharts option={chrtOpt} />
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

const PaymentStatisticsModal = (props) => {
    // const {
    // } = props;

    const { i18n, t } = useTranslation();

    const dispatch = useDispatch();

    const {sellerDetails} = useSellerDetails();

    const sellersList = sellerDetails?.sellers;
    let sellersList_asOptions = sellersList?.length && sellersList.map(seller => {
        return ({
            label: seller.name,
            value: seller.id
        })
    });
    const allSellers_opt = {
        label: t("allSellers"),
        value: "all_sellers"
    }
    sellersList_asOptions = sellersList_asOptions && [allSellers_opt].concat(sellersList_asOptions);


    const showModal = useSelector(state => state.paymentManagement.showOpenPaymentsModal);

    const sellersOpenPaymentDataList = useSelector(state => state.paymentManagement.sellersOpenPaymentDataList);

    const selectedSellerOpt = useSelector(state => state.paymentManagement.selectedSellerInOpenPaymentModal);


    const defaultSeller = sellersList?.length && sellersList[0];

    useEffect(() => {
        if (!sellersList?.length) {
            handleShowOpenPaymentsData();
        }
        else if (!selectedSellerOpt || !selectedSellerOpt?.id) {
            handleShowOpenPaymentsData(defaultSeller);
        }
    }, []);

    useEffect(() => {
        if (selectedSellerOpt && selectedSellerOpt?.id) {
            handleShowOpenPaymentsData(selectedSellerOpt);
        }
    }, [selectedSellerOpt]);


    const handleCloseModal = () => {
        dispatch(toggleOpenPaymentsModal({
            showModal: false,
        }));
    };


    const handleChangeSelectedSeller = (newOpt) => {
        const selectedItem_data = {
            id: newOpt?.value,
            name: newOpt?.label
        };

        dispatch(changeSelectedSellerInOpenPaymentModal({
            selectedOption: selectedItem_data
        }));
    };

    const handleShowOpenPaymentsData = async (slctdOpt) => {
        dispatch(changeSellersOpenPaymentDataList({
            dataList: []
        }));

        const allSelersShow_isSelected = slctdOpt?.id === "all_sellers";
        if (!allSelersShow_isSelected) {
            const sellerId = slctdOpt?.id;
            const sellerName = slctdOpt?.name;

            const response = await fetchOpenPayDetails(sellerId);
            if (response?.status === 200) {
                const result = response.data;
                const open_payments = result?.data;

                dispatch(changeSellersOpenPaymentDataList({
                    dataList: [{
                        details: open_payments,
                        sellerId,
                        sellerName
                    }]
                }));
            }
            // else {
            //     // 
            // }
        }
        else {
            const response = await fetchOpenPayDetailsForAllSellers(sellersList);
            // if (response?.status === 200) {
            if (response?.status) {
                const sellersOpenPaymentDataList = response?.data;

                dispatch(changeSellersOpenPaymentDataList({
                    dataList: sellersOpenPaymentDataList
                }));
            }
            // else {
            //     // 
            // }
        }
    };


    return (
        <CustomModal
            size="lg"
            show={showModal}
            onHide={handleCloseModal}
            className="open-payments-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-capitalize">
                    {t("openPaymentsStatistics")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {sellersList?.length && (
                    <div className="d-flex align-items-center mt-2 mx-3">
                        <h5 className="me-4 mb-0">
                            {t("chooseYourSeller")}:
                        </h5>
                        <span className="choose-seller-filter-select">
                            <Select
                                id=""
                                // title="sellers select"
                                // name="sellers select"
                                classNamePrefix="select"
                                // className={`basic-single custom-select__menu-scrollbar`}
                                // className="form-control"
                                // styles={selectStyles}
                                options={sellersList_asOptions}
                                // placeholder={t('chooseYourSeller')}
                                defaultValue={sellersList_asOptions.find(seller => seller.value === defaultSeller?.id)}
                                onChange={handleChangeSelectedSeller}
                                value={sellersList_asOptions.find(seller => seller.value === selectedSellerOpt?.id)}
                                // menuPortalTarget={document.body}
                                isClearable={false}
                                isSearchable={true}
                                menuPlacement="bottom"
                            />
                        </span>
                    </div>
                )}

                {!sellersOpenPaymentDataList?.length ? (
                    <PaymentStatisticsPlaceholder />
                ) : (
                    <div className="mt-4">
                        {sellersOpenPaymentDataList.map((openPay_item, index) => (
                            <OpenPaymentsOfOneSeller
                                // key={openPay_item.sellerId}
                                key={index}
                                openPaymentsData={openPay_item}
                            />
                        ))}
                    </div>
                )}
            </Modal.Body>
            <Modal.Footer className="text-end">
                <button
                    className="btn btn-lg btn-dark ms-3 me-3 fw-medium text-uppercase"
                    onClick={handleCloseModal}
                >
                    {t("close")}
                </button>
            </Modal.Footer>
        </CustomModal>
    )
}

export default PaymentStatisticsModal;

