
export default [
    {
        id: 'ordersListShowStep',
        title: 'receipts',
        titleId: 'receipts',
        stepContentClassName: "orders-list-show-step pt-2 mt-1",
    },
    {
        id: 'availableItemsShowStep',
        // title: 'items',
        title: 'available items',
        titleId: 'availableItems',
        stepContentClassName: "available-items-list-show-step",
    },
    {
        id: 'itemDetailsPreviewStep',
        // title: 'item details',
        title: 'add item',
        titleId: 'addItem',
        stepContentClassName: "step-item-content edit-and-preview-box d-flex flex-column h-100 px-0 pb-3",
    }
];
