import { useTranslation } from "react-i18next";
import KeyboardReact from "react-simple-keyboard";

import "react-simple-keyboard/build/css/index.css";

import "./CustomSimpleKeyboard.scss";


const creditCardIconHtml = '<span class="icon btn-icon me-2 fs-3"><svg width="22.312" height="22.312" viewBox="0 0 22.5 22.5" fill="currentColor" fill-rule="evenodd" xmlns="http://www.w3.org/2000/svg"><path d="M4.031 4.406c-1.036 0-1.875.839-1.875 1.875v9.75c0 1.035.839 1.875 1.875 1.875h14.25c1.035 0 1.875-.84 1.875-1.875v-9.75c0-1.036-.84-1.875-1.875-1.875zM.656 6.281c0-1.864 1.511-3.375 3.375-3.375h14.25c1.864 0 3.375 1.511 3.375 3.375v9.75c0 1.864-1.511 3.375-3.375 3.375H4.031c-1.864 0-3.375-1.511-3.375-3.375z"/><path d="M0 8.156C0 7.38.63 6.75 1.406 6.75h19.5c.777 0 1.406.63 1.406 1.406s-.63 1.406-1.406 1.406h-19.5C.63 9.562 0 8.933 0 8.156zm3.75 5.063c0-.777.63-1.406 1.406-1.406h2.25c.777 0 1.406.63 1.406 1.406v.938c0 .777-.63 1.406-1.406 1.406h-2.25c-.777 0-1.406-.63-1.406-1.406z"/></svg></span>';

const cashIconHtml = '<span class="icon btn-icon me-2 fs-4"><svg width="22.5" height="22.5" viewBox="0 0 22.5 22.5" fill="currentColor" fill-rule="evenodd" xmlns="http://www.w3.org/2000/svg"><path d="M22.5 14.25a1.5 1.5 0 0 1-1.5 1.5H1.5a1.5 1.5 0 0 1-1.5-1.5V3.75a1.5 1.5 0 0 1 1.5-1.5H21a1.5 1.5 0 0 1 1.5 1.5zm-21 0H21V3.75H1.5zm0 3a.75.75 0 0 1 .75-.75h18a.75.75 0 1 1 0 1.5h-18a.75.75 0 0 1-.75-.75zM3 19.5a.75.75 0 0 1 .75-.75h15a.75.75 0 1 1 0 1.5h-15A.75.75 0 0 1 3 19.5zM11.25 6a3 3 0 1 0 0 6 3 3 0 1 0 0-6zm-4.5 3a4.5 4.5 0 1 1 9 0 4.5 4.5 0 1 1-9 0z"/><path d="M4.787 2.307A.75.75 0 0 1 5.25 3a4.5 4.5 0 0 1-1.318 3.182A4.5 4.5 0 0 1 .75 7.5a.75.75 0 0 1-.693-.463.75.75 0 0 1 .163-.817l3.75-3.75a.75.75 0 0 1 .817-.163zm12.926 0a.75.75 0 0 1 .817.163l3.75 3.75a.75.75 0 0 1-.53 1.28 4.5 4.5 0 0 1-4.5-4.5.75.75 0 0 1 .463-.693zM.75 12a.75.75 0 0 1-.75-.75.75.75 0 0 1 .75-.75 4.5 4.5 0 0 1 3.182 1.318A4.5 4.5 0 0 1 5.25 15a.75.75 0 1 1-1.5 0 3 3 0 0 0-.879-2.121 3 3 0 0 0-.973-.65A3 3 0 0 0 .75 12zm17.818-.182A4.5 4.5 0 0 1 21.75 10.5a.75.75 0 0 1 .53 1.28l-3.75 3.75a.75.75 0 0 1-1.28-.53 4.5 4.5 0 0 1 1.318-3.182z"/></svg></span>';


const CustomSimpleKeyboard = (props) => {
    const {
        layoutName,
        theme,
        enterButtonText = "< enter", // or "return"
        backspaceButtonText = "⌫",   // or "backspace"
        shiftButtonText = "⇧",       // or "shift"
        clearButtonText = "Clear",
        disabled,
        ...restProps
    } = props;

    const { i18n, t } = useTranslation();


    const layouts = {
        default: [
            "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
            "{tab} q w e r t y u i o p [ ] \\",
            "{lock} a s d f g h j k l ; ' {enter}",
            "{shift} z x c v b n m , . / {shift}",
            ".com @ {space}"
        ],
        shift: [
            "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
            "{tab} Q W E R T Y U I O P { } |",
            '{lock} A S D F G H J K L : " {enter}',
            "{shift} Z X C V B N M < > ? {shift}",
            ".com @ {space}"
        ],
        symbols: [
            "[ ] { } # % ^ * + =",
            "_ \\ | ~ < > € £ ¥ ·",
            "{abc} {numbers} . , ? ! ' {backspace}",
            "{space}"
        ],
        numbersComplete: [
            "1 2 3 4 5 6 7 8 9 0",
            '- / : ; ( ) $ & @ "',
            "{abc} {symbols} . , ? ! ' {backspace}",
            "{space}"
        ],
        numbers: [
            "1 2 3",
            "4 5 6",
            "7 8 9",
            "{backspace} 0 {enter}"
        ],
        numbersForPayment: [
            "1 2 3 {arrowup} {payByCash}",
            "4 5 6 {arrowdown}",
            "7 8 9 {clear} {payByCreditCard}",
            "{numpaddecimal} 0 {backspace}"
        ],
        wideNumbersForPayment: [
            "1 2 3 4 {arrowup}",
            "5 6 7 8 {arrowdown}",
            "9 0 {numpaddecimal} {backspace}",
            "{payByCash} {payByCreditCard}"
        ],
        mobile: [
            "1 2 3 4 5 6 7 8 9 0",
            "q w e r t y u i o p",
            "a s d f g h j k l",
            "{shift} z x c v b n m {backspace}",
            "{numbers} {space} {enter}"
        ],
    };

    const display = {
        "{numbers}": "123",
        "{enter}": enterButtonText,
        "{escape}": "esc ⎋",
        "{space}": " ",
        "{tab}": "tab",             // "tab ⇥",
        "{backspace}": backspaceButtonText,
        "{capslock}": "caps",       // "caps lock ⇪",
        "{shift}": shiftButtonText,
        "{controlleft}": "ctrl",    // "ctrl ⌃",
        "{controlright}": "ctrl",   // "ctrl ⌃",
        "{altleft}": "alt",         // "alt ⌥",
        "{altright}": "alt",        // "alt ⌥",
        "{metaleft}": "cmd ⌘",
        "{metaright}": "cmd ⌘",
        "{abc}": "ABC",
        "{numpaddecimal}": ".",
        // "{up}": "&Hat;",
        "{arrowup}": "&#9650;",
        "{arrowdown}": "&#9660;",
        "{clear}": clearButtonText,
        "{payByCash}": `${cashIconHtml}<span>${t("payInCash")}</span>`,
        "{payByCreditCard}": `${creditCardIconHtml}<span>${t("payByCreditCard")}</span>`,
    };
    
    const buttonTheme = [
        // {
        //   class: "hg-red",
        //   buttons: "Q W E R T Y q w e r t y"
        // },
        // {
        //   class: "hg-highlight",
        //   buttons: "Q q"
        // }
    ];


    return (
        <KeyboardReact
            layoutName={layouts[layoutName] ? layoutName : "default"}
            layout={layouts}
            display={display}
            theme={`${disabled ? 'keyboard-disabled' : ''} ${theme}`}
            buttonTheme={buttonTheme}
            {...restProps}
        />
    )
}

export default CustomSimpleKeyboard;
