import { createSlice } from "@reduxjs/toolkit";


const transactionsSlice = createSlice({
    name: 'transactions',
    initialState: {
        showTransactionModal: false,
        currentTransactionDetails: {},
        showEmailPromptModal: false,
        targetCheckoutDetails: {},
    },
    reducers: {
        toogleTransactionDetailsModalOfPayment: (state, action) => {
            const {
                showModal,
            } = action.payload || {};

            if (showModal === undefined) {
                return;
            }
            state.showTransactionModal = showModal;
            state.currentTransactionDetails = {};
        },
        setTransactionDetails: (state, action) => {
            const {
                details = {},
            } = action.payload || {};

            state.currentTransactionDetails = details;
        },
        toogleGetEmailPromptModal: (state, action) => {
            const {
                showModal,
            } = action.payload || {};

            if (showModal === undefined) {
                return;
            }
            state.showEmailPromptModal = showModal;
        },
        setTargetCheckoutDetails: (state, action) => {
            const {
                checkoutDetails,
            } = action.payload;

            state.targetCheckoutDetails = checkoutDetails || {};
        },
    }
});


export const {
    toogleTransactionDetailsModalOfPayment,
    toogleGetEmailPromptModal,
    setTransactionDetails,
    setTargetCheckoutDetails,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
