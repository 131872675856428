import { createSlice } from "@reduxjs/toolkit";


const paymentManagementSlice = createSlice({
    name: 'paymentManagement',
    initialState: {
        partialPaymentIsPossible: false,
        referenceOrderDetails: {},
        selectedItemsForPayment: [],
        selectedCustomItemsForPayment: [],
        itemsForPaymentPrices: [],
        basePaymentAmount: 0,
        payableItemsAreLoading: false,
        showOpenPaymentsModal: false,
        selectedSellerInOpenPaymentModal: null,
        sellersOpenPaymentDataList: [],
        posPaymentAlertReminderHasBeenSet: undefined,
    },
    reducers: {
        updateReferenceOrderDetails: (state, action) => {
            const {
                orderDetails = {},
            } = action.payload || {};

            state.referenceOrderDetails = orderDetails;
            state.payableItemsAreLoading = false;
        },
        changePaymentItemCount: (state, action) => {
            const {
                id,
                count,
            } = action.payload || {};

            if (count === 0) {
                state.selectedItemsForPayment = state.selectedItemsForPayment.filter(it => it.id !== id);
            }
            else if (count > 0) {
                const item = state.selectedItemsForPayment?.length && state.selectedItemsForPayment.find(it => it.id === id);

                if (!item) {
                    state.selectedItemsForPayment.push({
                        id: id,
                        count: count
                    });
                }
                else {
                    state.selectedItemsForPayment = state.selectedItemsForPayment.map(it => {
                        if (it.id === id) {
                            return {
                                ...it,
                                count: count
                            }
                        }
                        else {
                            return it;
                        }
                    });
                }
            }
        },
        changePaymentCustomItemCount: (state, action) => {
            const {
                id,
                count,
            } = action.payload || {};

            if (count === 0) {
                state.selectedCustomItemsForPayment = state.selectedCustomItemsForPayment.filter(it => it.id !== id);
            }
            else if (count > 0) {
                const item = state.selectedCustomItemsForPayment?.length && state.selectedCustomItemsForPayment.find(it => it.id === id);

                if (!item) {
                    state.selectedCustomItemsForPayment.push({
                        id: id,
                        count: count
                    });
                }
                else {
                    state.selectedCustomItemsForPayment = state.selectedCustomItemsForPayment.map(it => {
                        if (it.id === id) {
                            return {
                                ...it,
                                count: count
                            }
                        }
                        else {
                            return it;
                        }
                    });
                }
            }
        },
        updatePayableItemPrice: (state, action) => {
            const {
                id,
                totalPrice,
            } = action.payload || {};

            if (totalPrice === 0) {
                state.itemsForPaymentPrices = state.itemsForPaymentPrices.filter(it => it.id !== id);
            }
            else if (totalPrice > 0) {
                const item = state.itemsForPaymentPrices?.length && state.itemsForPaymentPrices.find(it => it.id === id);

                if (!item) {
                    state.itemsForPaymentPrices.push({
                        id: id,
                        totalPrice: totalPrice
                    });
                }
                else {
                    state.itemsForPaymentPrices = state.itemsForPaymentPrices.map(it => {
                        if (it.id === id) {
                            return {
                                ...it,
                                totalPrice: totalPrice
                            }
                        }
                        else {
                            return it;
                        }
                    });
                }
            }

            state.basePaymentAmount = state.itemsForPaymentPrices?.length && state.itemsForPaymentPrices?.reduce((acc, currVal) => acc + (+currVal?.totalPrice || 0), 0) || 0;
        },
        resetPayableItems: (state, action) => {
            state.selectedItemsForPayment = [];
            state.selectedCustomItemsForPayment = [];
            state.referenceOrderDetails = {};
            state.itemsForPaymentPrices = [];
            state.basePaymentAmount = 0;
        },
        activeLoaderForItems: (state, action) => {
            state.payableItemsAreLoading = true;
        },
        toggleOpenPaymentsModal: (state, action) => {
            const {
                showModal = !state.showOpenPaymentsModal
            } = action.payload || {};

            state.showOpenPaymentsModal = showModal;
        },
        changeSelectedSellerInOpenPaymentModal: (state, action) => {
            const {
                selectedOption,
            } = action.payload || {};

            state.selectedSellerInOpenPaymentModal = selectedOption;
        },
        changeSellersOpenPaymentDataList: (state, action) => {
            const {
                dataList = []
            } = action.payload || {};

            state.sellersOpenPaymentDataList = dataList;
        },
        toggleShowingPosPaymentAlertReminder: (state, action) => {
            const {
                setReminder,
            } = action.payload || {};

            state.posPaymentAlertReminderHasBeenSet = setReminder;
        },
    }
});


export const {
    updateReferenceOrderDetails,
    changePaymentItemCount,
    changePaymentCustomItemCount,
    updatePayableItemPrice,
    resetPayableItems,
    activeLoaderForItems,
    toggleOpenPaymentsModal,
    changeSelectedSellerInOpenPaymentModal,
    changeSellersOpenPaymentDataList,
    toggleShowingPosPaymentAlertReminder,
} = paymentManagementSlice.actions;

export default paymentManagementSlice.reducer;
