import { useState } from 'react';

export default function useSellerDetails() {
    const getSellerDetails = () => {
        const sellerDetailsString = localStorage.getItem('sellerDetails');
        let sellerDetailsObj;
        try {
            sellerDetailsObj = JSON.parse(sellerDetailsString);
        } catch (error) {
            sellerDetailsObj = {};
        }
        return sellerDetailsObj;
    };

    const [sellerDetails, setSellerDetails] = useState(getSellerDetails());

    const saveSellerDetails = details => {
        localStorage.setItem('sellerDetails', JSON.stringify(details));
        setSellerDetails(details);
    };

    return {
        setSellerDetails: saveSellerDetails,
        sellerDetails
    }
}