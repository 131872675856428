import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { loginUser } from '../helpers/apiUtilities';
// import { baseURL } from '../dataProvider/configData';

import '../components/Authentication/Login.scss';

import { LANGUAGES } from '../constants';

import bannerImage from '../components/Authentication/pos-1.jpg';
import logoImage from '../components/images/digipos_logo.png';
import useSellerDetails from '../components/useSellerDetails';
import { useTranslation } from 'react-i18next';

// import Select from 'react-select';

import { CircleSpinner } from "../components/Loaders";

import CustomSimpleKeyboard from '../components/CustomSimpleKeyboard';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import toast, { Toaster } from 'react-hot-toast';
import { PlusSquareIcon } from '../components/svgIcons/AllIcons';


// const loginUser = async (credentials) => {
//     return fetch(`${baseURL}/auth/login`, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(credentials)
//     })
//     .then(data => data.json())
// }


const Login = (props) => {
    const {
        setToken,
        // setWaiterIsLogedIn,
    } = props;

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { i18n, t } = useTranslation();

    const { sellerDetails, setSellerDetails } = useSellerDetails();


    const [selectedLanguageValue, setSelectedLanguageValue] = useState(LANGUAGES && LANGUAGES[0]?.value || 'en')

    // const [submitedAtleastOnce, setSubmitedAtleastOnce] = useState(false);

    // const [waiterUserId, setWaiterUserId] = useState("");
    // const [pinCode, setPinCode] = useState("");

    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");


    const [errText, setErrText] = useState("");


    const [formIsSubmitting, setFormIsSubmitting] = useState(false);


    const showKeyboard = useSelector(state => state.appGeneral.showKeyboard);

    // const [keyboardLayoutName, setKeyboardLayoutName] = useState("mobile");
    const [keyboardLayoutName, setKeyboardLayoutName] = useState("numbers");
    const keyboard = useRef();


    const [activeInput, setActiveInput] = useState(null);

    const [showPasswordText, setShowPasswordText] = useState(false);


    const usernameInputRef = useRef(null);
    const passwordInputRef = useRef(null);


    // const waiters_l = [
    //     // { label: "Choose your username", value: "" },
    //     { label: "Waiter A", value: "waiter_a" },
    //     { label: "Waiter B", value: "waiter_b" },
    // ];


    // const waiterPinCodes_mockArr = {
    //     "waiter_a": 1234,
    //     "waiter_b": 5678
    // };

    const initialLanguageCode = localStorage.getItem("langCode") || 'en';

    useEffect(() => {
        i18n.changeLanguage(initialLanguageCode);
        setSelectedLanguageValue(initialLanguageCode);

        const searchParams_authErr = searchParams.get('auth_err');
        if (searchParams_authErr === "true") {
            setSearchParams({});

            toast.error(t("yourAuthenticationFailed") + "!", {
                duration: 8000,
            });
        }
    }, []);


    const changeLanguage = (lang) => {
        const lang_code = lang?.value;

        setSelectedLanguageValue(lang_code);

        localStorage.setItem('langCode', lang_code);
        i18n.changeLanguage(lang_code);

        // window.location.reload(false);
    };

    const handleSubmit = async e => {
        e.preventDefault();

        setFormIsSubmitting(true);

        const response = await loginUser({
            username,
            password
        });

        if (response?.status === 200) {
            const result = response.data;
            const response_data = result?.data || {};

            const token = response_data?.access_token;
            setFormIsSubmitting(false);
            setToken(token);

            const {
                currency_symbol = '',
                logo,
                name = '',
                user_name = '',   // user Id or Email
                sellerHasSellersListAccess,
                sellers,
                is_demo_mode: isDemoMode
            } = response_data || {};

            setSellerDetails({
                currency_symbol,
                logo,
                name,
                user_name,
                sellerHasSellersListAccess,
                sellers,
                isDemoMode,
            });

            navigate("/");
        }
        else {
            const axios_err = response?.message;
            const err_message = response?.response?.data?.message || response?.response?.data?.errors?.error || axios_err || '';

            setErrText(err_message);
            setFormIsSubmitting(false);
        }

        // if (response?.status === true) {
        //     const response_data = response.data || {};

        //     const token = response_data?.access_token;
        //     setFormIsSubmitting(false);
        //     setToken(token);

        //     setSellerDetails({
        //         currency_symbol: response_data?.currency_symbol || '',
        //         sellerHasSellersListAccess: response_data?.sellerHasSellersListAccess,
        //         sellers: response_data?.sellers,
        //     });

        //     navigate("/");
        // }
        // else {
        //     const err_message = response?.errors?.error;
        //     setErrText(err_message);
        //     setFormIsSubmitting(false);
        // }


        // if (!submitedAtleastOnce) {
        //     setSubmitedAtleastOnce(true);
        // }

        // if (+waiterPinCodes_mockArr[waiterUserId] === +pinCode) {
        //     setWaiterIsLogedIn(true);
        // }
        // else {
        //     setErrText("Your pin is incorrect");
        // }
    };


    // useEffect(() => {
    //     // document.body.style.backgroundColor = "rgba(90, 115, 211, 0.9)";
    //     document.body.style.backgroundColor = "rgba(6, 98, 178, 0.9)";
    //     return () => {
    //         document.body.style.backgroundColor = "";
    //     }
    // }, []);


    const handleChangeUsername = (e) => {
        const inputValue = e.target.value;
        setUserName(inputValue);

        if (showKeyboard) {
            keyboard.current.setInput(inputValue);
        }
    };

    const handleChangePassword = (e) => {
        const inputValue = e.target.value;
        setPassword(inputValue);

        if (showKeyboard) {
            keyboard.current.setInput(inputValue);
        }
    };


    // const handleChangeWaiter = (newValue, actionMeta) => {
    //     const lang_code = newValue?.value;
    //     setWaiterUserId(lang_code);
    // };

    // const handleChangePin = (e) => {
    //     const pinValue = e.target.value;
    //     setPinCode(pinValue);

    //     if (submitedAtleastOnce) {
    //         if (pinValue !== '') {
    //             setErrText("");
    //         }
    //         else {
    //             setErrText("Your pin is incorrect");
    //         }
    //     }
    // };


    const handleFocusOnInput = (e) => {
        const inputElem = e.target;
        setActiveInput(inputElem);

        if (showKeyboard) {
            keyboard.current.setInput(inputElem.value);
        }
    };

    // const handleBlurInput = (e) => {
    // };

    const handleChangeKeyboard = (inputVal, event) => {
        if (activeInput === usernameInputRef.current) {
            const newUsernameValue = inputVal;
            setUserName(newUsernameValue);
        }
        else if (activeInput === passwordInputRef.current) {
            const newPasswordValue = inputVal;
            setPassword(newPasswordValue);
        }

        // setTimeout(() => {
        //     console.log(activeInput);
        //     activeInput?.focus();
        // }, 0);
    };

    const handleKeyboardKeyPress = (key, event) => {
        if (!activeInput) {
            return;
        }
        
        if (key === "{backspace}") {
            if (activeInput === usernameInputRef.current) {
                const newUsernameValue = username?.toString().slice(0, -1);
                setUserName(newUsernameValue);
            }
            else if (activeInput === passwordInputRef.current) {
                const newPasswordValue = password?.toString().slice(0, -1);
                setPassword(newPasswordValue);
            }
        }
        else if (key === "{enter}") {
            if (activeInput === usernameInputRef.current) {
                setActiveInput(passwordInputRef.current);
            }
            else if (activeInput === passwordInputRef.current) {
                setActiveInput(null);
            }
        }
    };


    return (
        <>
            <Toaster/>

            <div className="login-page-wrapper">
                <div className="login-box-wrapper d-flex mt-4 mb-5">
                    <div className="login-box-content card d-flex flex-column align-items-center">
                        <span className="languages-select-box">
                            {LANGUAGES?.length && LANGUAGES.map(lang => (
                                <span
                                    key={lang.value}
                                    className={`lang-item ${selectedLanguageValue === lang.value ? 'active' : ''}`}
                                    onClick={() => changeLanguage(lang)}
                                >
                                    {lang.value}
                                </span>
                            ))}
                        </span>
                        <div>
                            <div className="mt-3 mb-4">
                                <img
                                    src={logoImage}
                                    className="logo-img"
                                    width="267"
                                    height="257"
                                    alt="digiPOS logo"
                                />
                            </div>
                            <h4
                                className="card-title mb-5"
                            >
                                {t("digiPosCashBoxLogin")}
                            </h4>
                        </div>
                        <form
                            className="d-flex flex-column flex-grow-1 w-100 mt-2 px-2"
                            onSubmit={handleSubmit}
                        >
                            <div className="d-flex flex-column text-start flex-grow-1">
                                {errText && (
                                    <div className="text-red mb-2 px-1">
                                        {/* Invalid email or password. */}
                                        <span className="fs-4">&bull;</span>
                                        <span className="mt-1 ms-1">{errText}</span>
                                    </div>
                                )}

                                <div className="mb-3">
                                    <label className="form-label ps-1">
                                        {t("username")}
                                    </label>
                                    <input
                                        type="text"
                                        className={`form-control ${activeInput === usernameInputRef.current ? 'focused' : ''}`}
                                        ref={usernameInputRef}
                                        value={username}
                                        onChange={handleChangeUsername}
                                        onFocus={handleFocusOnInput}
                                    />

                                    {/* <div className="select2-elem-wrapper">
                                        <Select
                                            // id=""
                                            // title="username"
                                            name="userId"
                                            classNamePrefix="select"
                                            // className={`basic-single custom-select__menu-scrollbar`}
                                            // className="form-control"
                                            // styles={}
                                            options={waiters_l}
                                            defaultValue={waiters_l[0]}
                                            onChange={handleChangeWaiter}
                                            value={waiters_l.find(waiter => waiter.value === waiterUserId) || ''}
                                            // formatOptionLabel={handleFormatOptionLabel}
                                            // menuPortalTarget={document.body}
                                            // isDisabled={false}
                                            // isLoading={false}
                                            isClearable={false}
                                            isSearchable={false}
                                            // menuPlacement="bottom"
                                            placeholder="Choose your username"
                                        />
                                    </div> */}
                                </div>

                                <div className="mb-3">
                                    <label className="form-label ps-1">
                                        {t("password")}
                                        {/* {t("pinCode")} */}
                                    </label>
                                    <div className={`toggleable-password ${showPasswordText ? 'show-password' : ''}`}>
                                        <div className='form-control-wrapper'>
                                            <input
                                                // type="number"
                                                // type="password"
                                                type={`${showPasswordText ? 'text' : 'password'}`}
                                                name="pin-code"
                                                className={`form-control password ${activeInput === passwordInputRef.current ? 'focused' : ''}`}
                                                ref={passwordInputRef}
                                                value={password}
                                                onInput={handleChangePassword}
                                                onFocus={handleFocusOnInput}
                                                // disabled={!waiterUserId}
                                            />
                                            <span
                                                className='eye-btn'
                                                onClick={() => setShowPasswordText(!showPasswordText)}
                                            ></span>
                                            {/* {errText && (
                                                <div className="text-red fst-italic mt-1 px-1">
                                                    { errText }
                                                </div>
                                            )} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="text-end mb-5">
                                <a href="#" className="text-underline">
                                    Forget your PIN ?
                                </a>
                            </div> */}
                            <div className="mt-4 mb-4">
                                <button
                                    type="submit"
                                    className="btn btn-lg submit-btn btn-success px-5 rounded-3 text-uppercase"
                                    // disabled={!waiterUserId || !pinCode}
                                    disabled={formIsSubmitting}
                                >
                                    {formIsSubmitting && (
                                        <span className="icon btn-icon">
                                            <CircleSpinner
                                                spinnerColor="#fff"
                                                circleOpacity={0}
                                                thickness={5}
                                            />
                                        </span>
                                    )}
                                    <span className="btn-text px-3">
                                        {t("login")}
                                        {/* Continue */}
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className={`login-box-banner ${showKeyboard ? 'banner-with-keyboard' : ''}`}>
                        <div className="banner-image">
                            <img
                                src={bannerImage}
                                className=""
                                width="800"
                                height="600"
                                alt=""
                            />
                        </div>

                        {showKeyboard &&(
                            <CustomSimpleKeyboard
                                keyboardRef={r => (keyboard.current = r)}
                                layoutName={keyboardLayoutName}
                                theme={`hg-theme-default hg-layout-default ${keyboardLayoutName === "numbers" ? 'simple-numbers-keyboard' : ''}`}
                                enterButtonText="Next"
                                // enterButtonText={`${activeInput !== passwordInputRef.current ? 'Next >' : 'Done'}`}
                                onChange={handleChangeKeyboard}
                                onKeyPress={handleKeyboardKeyPress}
                                // mergeDisplay={true}
                                // autoUseTouchEvents={true}
                                // useButtonTag={true}
                                disabled={!activeInput}
                            />
                        )}
                    </div>

                    <button
                        className="pwa-install-prompt-btn btn btn-secondary d-none"
                        title={t("addDAPToHomeScreen")}
                    >
                        <span className="icon">
                            <PlusSquareIcon />
                        </span>
                        <span className="btn-text-wrapper">
                            <span className="btn-text">
                                {t("installDAP")}
                            </span>
                        </span>
                    </button>
                </div>
            </div>
        </>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}

export default Login;
