import Select from 'react-select';
import './Header.scss';

import logoIcon from './../../components/images/digipos_logo.png';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoaderForAddedOrdersBox, refreshDashboardData, selectOrder, activateLoaderForOrdersList } from '../../store/slices/stepManagementSlice';
import { LANGUAGES } from '../../constants';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { changeDemoModeState, setShowDemoModeBtnLoader, changeMobileDrawerMenuShowState, toggleSwapOnOrderAddedItemsBox } from '../../store/slices/appGeneralSlice';
import { PlusSquareIcon } from '../svgIcons/AllIcons';
import { changeDemoMode } from '../../helpers/apiUtilities';
import useSellerDetails from '../useSellerDetails';
import { CircleSpinner } from '../Loaders';
import { toggleOpenPaymentsModal } from '../../store/slices/paymentManagementSlice';
// import useToken from '../../Routes/Login/useToken';


const Header = (props) => {
    const {
        handleNavigateToLoginPage,
    } = props;


    const { i18n, t } = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const location = useLocation();
    const currentPath = location.pathname;

    const isOnDashboardPage = currentPath === '/';

    const [searchParams, setSearchParams] = useSearchParams();

    const { sellerDetails, setSellerDetails } = useSellerDetails();


    const isDemoMode = useSelector(state => state.appGeneral.isDemoMode);
    const isMobileView = useSelector(state => state.appGeneral.isMobileView);

    const showOrderAddedItemsBoxInMobileView = useSelector(state => state.appGeneral.showOrderAddedItemsBoxInMobileView);

    const showDemoModeBtnLoader = useSelector(state => state.appGeneral.showDemoModeBtnLoader);

    const initialLanguageCode = localStorage.getItem("langCode") || 'en';


    useEffect(() => {
        i18n.changeLanguage(initialLanguageCode);
        
        dispatch(changeDemoModeState({
            isDemoMode: !!sellerDetails?.isDemoMode,
        }));
    }, []);


    const handleUserLogout = (e) => {
        e.preventDefault();
        handleNavigateToLoginPage();

        setSellerDetails({});
    };

    const onChangeLang = (newOpt, actionMeta) => {
        const lang_code = newOpt?.value;
        localStorage.setItem('langCode', lang_code);
        i18n.changeLanguage(lang_code);

        window.location.reload(false);
    };

    const HandleGoBackToTheDahboardPage = () => {
        const currentSearchParams_orderId = Number(searchParams.get('orderId'));
        // navigate(-1);
        navigate(`/?orderId=${currentSearchParams_orderId}`);

        dispatch(refreshDashboardData());
    };

    const handleUnselectOrder = () => {
        dispatch(selectOrder({
            orderId: null,
        }));

        dispatch(toggleLoaderForAddedOrdersBox({
            isLoading: false
        }));

        setSearchParams({});
    };

    const handleBackToOrdersPreviewBox = () => {
        dispatch(toggleSwapOnOrderAddedItemsBox({
            swapToOrderAddedItemsBox: false
        }));
        handleUnselectOrder();
    };

    const handleToggleDemoMode = async () => {
        setSearchParams({});
        
        dispatch(selectOrder({
            orderId: null,
        }));
        dispatch(activateLoaderForOrdersList());
        // dispatch(setOrdersListData({
        //     ordersList: [],
        //     orderId: null,
        // }));

        dispatch(setShowDemoModeBtnLoader({
            show: true
        }));

        const response = await changeDemoMode(!isDemoMode);
        if (response?.status === 200) {
            const result = response.data;
            const vendor_det = result?.data;

            const is_demoMode = vendor_det?.is_demo_mode;
            dispatch(changeDemoModeState({
                isDemoMode: is_demoMode,
            }));

            setSellerDetails({
                ...sellerDetails,
                isDemoMode: is_demoMode,
            });

            dispatch(setShowDemoModeBtnLoader({
                show: false
            }));

            dispatch(refreshDashboardData());
        }
        // else {
        //     // 
        // }
    };

    const handleShowOpenPeysModal = async () => {
        dispatch(toggleOpenPaymentsModal({
            showModal: true,
        }));
    };

    const handleShowMobileDrawerMenu = () => {
        dispatch(changeMobileDrawerMenuShowState({
            show: true
        }));
    };


    return (
        <header className="header-navbar">
            <div className="navbar navbar-inner">
                <div className="navbar-menu d-flex flex-grow-1">
                    {!isOnDashboardPage ? (
                        <div className="d-flex align-items-center text-left float-left me-3">
                            <button
                                className="btn icon-btn btn-outline-secondary rounded-circle"
                                onClick={HandleGoBackToTheDahboardPage}
                            >
                                <span className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" fillRule="evenodd"><path d="M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z"/><path d="M8.707.293a1 1 0 0 1 0 1.414L2.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 0z"/></svg>
                                </span>
                            </button>
                        </div>
                    ) : (isMobileView && showOrderAddedItemsBoxInMobileView) && (
                        <button
                            className="btn btn-outline-secondary w-auto me-2 d-flex align-items-center px-3 py-0 me-3"
                            onClick={handleBackToOrdersPreviewBox}
                        >
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" fillRule="evenodd"><path d="M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z"/><path d="M8.707.293a1 1 0 0 1 0 1.414L2.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414l-7-7a1 1 0 0 1 0-1.414l7-7a1 1 0 0 1 1.414 0z"/></svg>
                            </span>
                            <span className="ms-2">
                                {t("receiptsList")}
                            </span>
                        </button>
                    )}

                    {(isOnDashboardPage && true) && (
                        <span className="me-2">
                            <button
                                className={`dm-switch-btn p-0 border-0 ${isDemoMode ? 'active' : ''} ${showDemoModeBtnLoader ? 'show-loader' : ''}`}
                                onClick={handleToggleDemoMode}
                                disabled={showDemoModeBtnLoader}
                            >
                                {showDemoModeBtnLoader && (
                                    <span className="icon loader-icon">
                                        <CircleSpinner
                                            circleOpacity={0}
                                            spinnerColor="#fff"
                                            thickness={5}
                                        />
                                    </span>
                                )}
                                <span className="btn-text">
                                    D
                                </span>
                            </button>
                        </span>
                    )}

                    <span>
                        <button
                            className="pwa-install-prompt-btn btn btn-flat"
                            title={t("addDAPToHomeScreen")}
                            style={{
                                display: 'none'
                            }}
                        >
                            <span className="icon">
                                <PlusSquareIcon />
                            </span>
                            <span className="btn-text-wrapper">
                                <span className="btn-text">
                                    {t("installDAP")}
                                </span>
                            </span>
                        </button>
                    </span>

                    <div className="flex-grow-1 text-md-right clearfix d-flex justify-content-end">
                        {!isMobileView ? (
                            <>
                                <button
                                    className="btn btn-outline-success fw-medium me-3 text-capitalize px-1 px-sm-3"
                                    onClick={handleShowOpenPeysModal}
                                >
                                    {t("openPayments")}
                                </button>

                                <div className="navbar-language-select select2-elem-wrapper me-2 me-sm-4">
                                    <Select
                                        id="change_language_select"
                                        // title="language"
                                        // name="language"
                                        classNamePrefix="select"
                                        // className={`basic-single custom-select__menu-scrollbar`}
                                        // className="form-control"
                                        // styles={selectStyles}
                                        options={LANGUAGES}
                                        defaultValue={LANGUAGES[0]}
                                        onChange={onChangeLang}
                                        value={LANGUAGES.find(lang => lang.value === initialLanguageCode) || ''}
                                        // formatOptionLabel={handleFormatOptionLabel}
                                        // menuPortalTarget={document.body}
                                        // isDisabled={false}
                                        // isLoading={false}
                                        isClearable={false}
                                        isSearchable={true}
                                        menuPlacement="bottom"
                                    />
                                </div>
                                <div className="dropdown">
                                    <span
                                        className="user-navbar-dropdown-btn px-1 px-sm-2 d-flex align-items-center"
                                        id="userNavbarDropdownMenu"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={handleUserLogout}
                                    >
                                        <img
                                            src={logoIcon}
                                            className="header-profile-img"
                                            width="40"
                                            height="40"
                                            alt="digiPOS logo"
                                        />
                                        <span className="user-name">
                                            <span className="user-name-text text-capitalize">
                                                {/* user name */}
                                                {t("logout")}
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            </>
                        ) : (
                            <>
                                <button
                                    className="btn mobile-hamburger-btn px-3"
                                    onClick={handleShowMobileDrawerMenu}
                                >
                                    <span className="icon">
                                        <svg height="22pt" width="22pt" viewBox="0 -53 384 384" xmlns="http://www.w3.org/2000/svg"><path d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/><path d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"/></svg>
                                    </span>
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
