import { createSlice } from "@reduxjs/toolkit";


const appSlice = createSlice({
    name: 'appGeneral',
    initialState: {
        isDemoMode: false,
        showKeyboard: false,
        isMobileView: false,
        showMobileDrawerMenu: undefined,
        showOrderAddedItemsBoxInMobileView: false,
        receiptsListViewType: "grid",
        showDemoModeBtnLoader: false,
        showSelectOptionsModal: false,
        modalSelectOptionsList: [],
        // pendingRequests: {},
        // cancelingRequestTypeIds: [],
        // requestCancelationMethods: {},
    },
    reducers: {
        changeDemoModeState: (state, action) => {
            const {
                isDemoMode = state.isDemoMode,
            } = action.payload || {};

            state.isDemoMode = isDemoMode;
        },
        toggleMobileModeState: (state, action) => {
            const {
                isMobileView,
            } = action.payload || {};

            if (isMobileView === undefined) {
                return;
            }
            state.isMobileView = isMobileView;
        },
        changeMobileDrawerMenuShowState: (state, action) => {
            const {
                show
            } = action.payload || {};

            state.showMobileDrawerMenu = show;
        },
        toggleSwapOnOrderAddedItemsBox: (state, action) => {
            const {
                swapToOrderAddedItemsBox,
            } = action.payload || {};

            if (swapToOrderAddedItemsBox === undefined) {
                return;
            }
            state.showOrderAddedItemsBoxInMobileView = swapToOrderAddedItemsBox;
        },
        changeReceiptsListViewType: (state, action) => {
            const {
                viewType,
            } = action.payload || {};

            if (viewType === "grid" || viewType === "list") {
                state.receiptsListViewType = viewType;
            }
        },
        setShowDemoModeBtnLoader: (state, action) => {
            const {
                show = state.showDemoModeBtnLoader,
            } = action.payload || {};

            state.showDemoModeBtnLoader = show;
        },
        toggleSelectOptionsModal: (state, action) => {
            const {
                showModal = !state.showSelectOptionsModal,
                selectTitle = "",
                selectOptions = []
            } = action.payload || {};

            state.showSelectOptionsModal = showModal;
            state.modalSelectTitle = selectTitle;
            state.modalSelectOptionsList = selectOptions;
        },
        // addPendingRequest: (state, action) => {
        //     const {
        //         requestId,
        //         requestTypeId
        //     } = action.payload || {};

        //     const prev_reqTypeIdData = state.pendingRequests && state.pendingRequests[requestTypeId] || [];

        //     state.pendingRequests = {
        //         ...state.pendingRequests,
        //         [requestTypeId]: [
        //             ...prev_reqTypeIdData,
        //             requestId
        //         ]
        //     };
        // },
        // removePendingRequest: (state, action) => {
        //     const {
        //         requestId,
        //         requestTypeId
        //     } = action.payload || {};

        //     const new_requestsData = state.pendingRequests && state.pendingRequests[requestTypeId]?.length && state.pendingRequests[requestTypeId].filter(reqId => reqId !== requestId) || [];

        //     state.pendingRequests[requestTypeId] = new_requestsData;

        //     if (!new_requestsData.length) {
        //         state.cancelingRequestTypeIds = state.cancelingRequestTypeIds.filter(reqTypId => reqTypId !== requestTypeId);

        //         delete state.pendingRequests[requestTypeId];
        //     }
        // },
        // addCancelingRequest: (state, action) => {
        //     const {
        //         requestTypeId
        //     } = action.payload || {};

        //     state.cancelingRequestTypeIds.push(requestTypeId);
        // },
        // setRequestCancelationMethods: (state, action) => {
        //     const {
        //         type,
        //         requestId,
        //         requestCancelation
        //     } = action.payload || {};
            
        //     if (type === "delete") {
        //         const new_requestCancelationMethods = {...state.requestCancelationMethods};
        //         delete new_requestCancelationMethods[requestId];

        //         state.requestCancelationMethods = new_requestCancelationMethods;
        //     }
        //     else if (type === "add") {
        //         state.requestCancelationMethods[requestId] = requestCancelation;
        //     }
        // },
    }
});


export const {
    changeDemoModeState,
    toggleMobileModeState,
    changeMobileDrawerMenuShowState,
    toggleSwapOnOrderAddedItemsBox,
    changeReceiptsListViewType,
    setShowDemoModeBtnLoader,
    toggleSelectOptionsModal,
    // addPendingRequest,
    // removePendingRequest,
    // addCancelingRequest,
    // setRequestCancelationMethods,
} = appSlice.actions;

export default appSlice.reducer;
