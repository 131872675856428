// import axios from "axios";
import cashBoxApi from "../dataProvider/cashBoxApi";
import { History } from "./NavigateSetter";


const redirectToLoginPage = () => {
    // window.location.href = "/login";
    History.navigate({
        pathname: '/login',
        search: '?auth_err=true',
    });
}

const callCashBoxApi = async (config) => {
    let response;

    config = config || {};
    config.headers = config.headers || {};
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

    try {
        response = await cashBoxApi(config);
    }
    catch (err) {
        // if (axios.isCancel(err)) {
        //     console.warn(`The request was canceled! \n path: ${config.url} \n method: ${config.method}`)
        // }
        // else if (err?.response?.status === 401) {
        if (err?.response?.status === 401) {
            console.error(err?.message);
            redirectToLoginPage();
        }
        else {
            console.error(err?.message);
        }

        return err;
    }
    finally {
        // 
    }

    return response;
}

// const getPromiseWithCancelationMethod = (config) => {
//     const cancelToken = axios.CancelToken.source();
//     config.cancelToken = cancelToken.token;

//     const promise = callCashBoxApi(config);

//     return {
//         requestCancelation: cancelToken.cancel,
//         promise: () => promise
//     }
// };


export const loginUser = (credentials) => {
    const config = {
        method: 'post',
        url: `/auth/login`,
        data: credentials
    };
    return callCashBoxApi(config);
}

export const fetchOrdersList = (get_history) => {
    const config = {
        method: 'get',
        url: `/order/list?${get_history ? 'history_filter=true' : ''}`,
    };
    return callCashBoxApi(config);

    // return getPromiseWithCancelationMethod(config);
};

export const fetchOrderDetails = (orderId) => {
    const config = {
        method: 'get',
        url: `/order/details?order_id=${orderId}`,
    };
    return callCashBoxApi(config);
};

export const fetchCategoriesList = () => {
    var config = {
        method: 'get',
        url: `/category/list`,
    };
    return callCashBoxApi(config);
};

export const fetchAvailableItemsList = (cat_id) => {
    cat_id = (typeof cat_id === "string" || typeof cat_id === "number") ? cat_id : '';

    const sub_url = !cat_id || cat_id !== "subway" ? "item/list" : "custom_item/list";
    const config = {
        method: 'get',
        url: `/${sub_url}${cat_id ? '?cat_id=' + cat_id : ''}`,
    };

    return callCashBoxApi(config);
};

export const fetchCustomItemPreview = (option_ids) => {
    const config = {
        method: 'get',
        url: `/order/custom_item_preview`,
        data: {
            items: option_ids
        }
    };

    return callCashBoxApi(config);
};

export const fetchItemInfo = (itemId, isCustomItem) => {
    const sub_url = isCustomItem ? "custom_item/details" : "item/details";

    const config = {
        method: 'get',
        url: `/${sub_url}${isCustomItem ? '?group_code=' + itemId : '?id=' + itemId}`,
    };

    return callCashBoxApi(config);
};


export const createNewOrder = (sellerId) => {
    const data = sellerId ? {seller_id: sellerId} : {};

    const config = {
        method: 'post',
        url: `/order/create_order`,
        data,
    };

    return callCashBoxApi(config);
};

export const addToOrder = (currentOrderId, req_data_items, req_data_CustomItems) => {
    const config = {
        method: 'post',
        url: `/order/add_items_to_order`,
        data: {
            order_id: currentOrderId,
            ...req_data_items,
            ...req_data_CustomItems
        }
    };

    return callCashBoxApi(config);
};

export const cancelOrder = (currentOrderId) => {
    const config = {
        method: 'post',
        url: `/order/reject`,
        data: {
            order_id: currentOrderId
        }
    };

    return callCashBoxApi(config);
};


export const editItem = (new_data, isCustomItem) => {
    if (new_data === undefined || new_data === null) {
        return;
    }

    const sub_url = isCustomItem
        ? 'order/edit_item_of_custom_order'
        : 'order/edit_item_of_order';

    const config = {
        method: 'put',
        url: `/${sub_url}`,
        data: new_data
    };

    return callCashBoxApi(config);
};

export const deleteItem = (id, currentOrderId, isCustomItem) => {
    if (id === undefined || id === null) {
        return;
    }

    const sub_url = isCustomItem
        ? "order/delete_custom_order"
        : "order/delete_item_from_order";

    const config = {
        method: 'delete',
        url: `/${sub_url}`,
        data: {
            order_id: currentOrderId,
            order_item_id: id
        }
    };

    return callCashBoxApi(config);
};


export const payBills = (req_data) => {
    const config = {
        method: 'post',
        url: `/order/checkout`,
        data: req_data
    };

    return callCashBoxApi(config);
};

export const sendTransactionToEmail = (checkoutId, email) => {
    const config = {
        method: 'post',
        url: '/order/send_checkout_email',
        data: {
            checkout_id: checkoutId,
            email: email
        }
    }

    return callCashBoxApi(config);
};


export const changeDemoMode = (isDemoMode) => {
    const config = {
        method: 'put',
        url: `/setting/demo_mode`,
        data: {
            is_demo_mode: isDemoMode
        }
    };

    return callCashBoxApi(config);
};

export const changeReceiptMode = (orderId) => {
    const config = {
        method: 'post',
        url: `/order/convert_order`,
        data: {
            order_id: orderId
        }
    };

    return callCashBoxApi(config);
};


export const fetchOpenPayDetails = (sellerId) => {
    const config = {
        method: 'get',
        url: `/order/open_pays?${(sellerId !== null && sellerId !== undefined) ? 'seller_id=' + sellerId : ''}`,
    };

    return callCashBoxApi(config);
};

export const fetchOpenPayDetailsForAllSellers = (sellersList) => {
    if (!sellersList?.length) {
        return fetchOpenPayDetails();
    }
    else {
        const promisesList = [];
        sellersList.forEach(seller => {
            promisesList.push(() => {
                return new Promise(async (resolve, reject) => {
                    const response = await fetchOpenPayDetails(seller.id);
                    if (response?.status === 200) {
                        const openPaymentDataList = response.data?.data;
                        resolve({
                            details: openPaymentDataList,
                            sellerId: seller.id,
                            sellerName: seller.name
                        });
                    }
                    else {
                        reject(response);
                    }
                });
            });
        });

        const main_promise = async () => {
            let result;
            try {
                result = await Promise.all(promisesList.map(promiseItem => promiseItem()));

                // Here the result is an array, and it is better to return an object with a status code as the response, so that checking the response is easier and more reliable.
                return {
                    status: true,
                    data: result
                }
            }
            catch (error) {
                return error;
            }
        };

        return main_promise();
    }
}


// export const requestTypeIds = {
//     fetchOrdersList: "fetchOrdersList",
//     fetchAvailableItemsList: "fetchAvailableItemsList",
//     fetchItemInfo: "fetchItemInfo",
// };

