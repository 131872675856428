
let baseURL;
if (process.env.NODE_ENV === 'development') {
    baseURL = "https://digipos.pttp.me/api/v1/seller";
}
else {
    baseURL = "https://digiairpos.com/api/v1/seller";
}

// const access_token = access_token_testServer;

export {
    baseURL,
};