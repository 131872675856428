import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import './App.scss';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from '../Routes/Login';

import useToken from './Authentication/useToken';
import Header from './Layouts/Header';
import GetEmailPromptModal from './Transaction/GetEmailPromptModal';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMobileModeState } from '../store/slices/appGeneralSlice';
// import NotFound from '../Routes/NotFound';
import NavigateSetter from '../helpers/NavigateSetter';
import MobileDrawerMenu from './Layouts/MobileDrawerMenu';

import PaymentStatisticsModal from '../components/Payment/PaymentStatisticsModal';

// const Login = lazy(() => import('./Authentication/Login'));
const Dashboard = lazy(() => import('../Routes/Dashboard'));
const Payment = lazy(() => import('../Routes/Payment'));

const EmptyPageBox = () => (
    <div className="card cash-register-card flex-grow-1">
        <div className="card-main-content d-flex flex-column h-100 pb-2"></div>
        <div className="multi-step-process-box"></div>
    </div>
)

const LayoutAndHeader = (props) => {
    const {
        children,
        handleNavigateToLoginPage,
    } = props;

    const isMobileView = useSelector(state => state.appGeneral.isMobileView);

    const showOpenPaymentsModal = useSelector(state => state.paymentManagement.showOpenPaymentsModal);

    return (
        <div className="page-wrapper">
            <Header
                handleNavigateToLoginPage={handleNavigateToLoginPage}
            />
            {isMobileView && (
                <MobileDrawerMenu
                    handleNavigateToLoginPage={handleNavigateToLoginPage}
                />
            )}
            <div id="main_content" className="main-content-wrap d-flex">
                <div className="container-fluid container-limited mb-1 d-flex flex-column">
                    {
                        children
                    }
                </div>
            </div>

            {showOpenPaymentsModal && (
                <PaymentStatisticsModal />
            )}
        </div>
    )
};

const AuthenticationHandler = (props) => {
    const {
        children,
        ...restProps
    } = props;

    const { token, setToken } = useToken();

    const handleNavigateToLoginPage = () => {
        setToken("");
    };

    const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, {
            handleNavigateToLoginPage,
            // setToken,
            ...restProps
        })
    );

    return (
        token ? (
            childrenWithProps
        ) : (
            <Login
                setToken={setToken}
            />
        )
    );
}


function App() {
    const { token, setToken } = useToken();

    const dispatch = useDispatch();

    // const [searchParams, setSearchParams] = useSearchParams();
    // const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const currentOrderId = useSelector(state => state.stepManagement.currentOrderId);
    const orderId = searchParams.get('orderId') || currentOrderId;


    useEffect(() => {
        toggleMobileMode();
        window.addEventListener('resize', handleToggleMobileMode)
    }, []);


    // const [waiterIsLogedIn, setWaiterIsLogedIn] = useState(false);


    const toggleMobileMode = () => {
        const win_width = window.innerWidth;

        dispatch(toggleMobileModeState({
            isMobileView: win_width < 768
        }));
    };

    const debouncedMobileModeHandler = useMemo(
        () => debounce(toggleMobileMode, 100)
    , []);

    const handleToggleMobileMode = () => {
        debouncedMobileModeHandler();
    };


    // if(!token) {
    //     return (
    //         <Login
    //             setToken={setToken}
    //             // setWaiterIsLogedIn={setWaiterIsLogedIn}
    //         />
    //     )
    // }


    return (
        <BrowserRouter>
            <NavigateSetter />

            <Routes>
                <Route
                    path="/"
                    element={
                        <Suspense fallback={<EmptyPageBox />}>
                            <AuthenticationHandler>
                                <LayoutAndHeader>
                                    <Dashboard />
                                </LayoutAndHeader>
                            </AuthenticationHandler>
                        </Suspense>
                    }
                />
                <Route
                    path="/payment"
                    element={
                        <Suspense fallback={<EmptyPageBox />}>
                            {orderId ? (
                                <AuthenticationHandler>
                                    <LayoutAndHeader>
                                        <Payment />
                                    </LayoutAndHeader>
                                </AuthenticationHandler>
                            ) : (
                                <Navigate to="/" />
                            )}
                        </Suspense>
                    }
                />
                <Route
                    path="/login"
                    element={
                        // <Suspense fallback={<EmptyPageBox />}>
                            <AuthenticationHandler>
                                <Login
                                    setToken={setToken}
                                />
                            </AuthenticationHandler>
                        // </Suspense>
                    }
                />
                {/* <Route
                    path="/404"
                    element={
                        <NotFound />
                    }
                /> */}
                {/* <Route path="*" element={<Navigate to="/404" />} /> */}

                <Route path="*" element={<Navigate to="/" />} />
            </Routes>

            <GetEmailPromptModal />
        </BrowserRouter>
    )
}

export default App;
