import axios from "axios";
import { baseURL } from './configData';

// const token = localStorage.getItem('token');

const lang_code = localStorage.getItem("langCode") || 'en';


const instance = axios.create({
    baseURL,
    // timeout: 5000,
    headers: {
        'version': '1',
        'Accept': 'application/json',
        // 'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'Accept-Language': lang_code
    }
});

export default instance;