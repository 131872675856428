import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    // lng: "en",
    // lng: getCurrentLang(),
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        en: {
            translation: {
                login: "Login",
                logout: "Log out",
                digiPosLogin: "DigiPOS Login",
                digiPosCashBoxLogin: "DigiPOS Cash Box Login",
                username: "Username",
                password: "Password",
                pinCode: "PIN code",
                yourAuthenticationFailed: "Your authentication failed",
                addDAPToHomeScreen: "Add DigiPOS to your home screen",
                addToHomeScreen: "Add to home screen",
                installDAP: "Install DAP",
                language: "Language",
                chooseLanguage: "Choose language",
                pageNotFound: "Page not found",
                backToDashboard: "Back to dashboard",
                transferToDemo: "Transfer to demo",
                transferToLive: "Transfer to live",
                order: "Order",
                orders: "Orders",
                receipt: "Receipt",
                receipts: "Receipts",
                option: "Option",
                options: "Options",
                itemOptions: "Item options",
                empty: "Empty",
                row: "Row",
                count: "count",
                step: "step",
                title: "Title",
                createdAt: "Created at",
                free: "Free",
                freeItems: "free items",
                items: "items",
                item: "item",
                minimum: "minimum",
                maximum: "Maximum",
                min: "min",
                max: "max",
                all: "All",
                email: "Email",
                print: "Print",
                orderDetails: "Order details",
                noOrders: "No orders",
                noOrdersAdded: "No orders added",
                receiptDetails: "Receipt details",
                noReceipts: "No receipts",
                noReceiptsAdded: "No receipts added",
                noAddedItems: "No items have been added",
                createOrSelectOrderFirst: "Create/select an order at the first",
                createOrSelectReceiptFirst: "Create/select a receipt at the first",
                customItem: "Custom item",
                customItems: "Custom items",
                normalItem: "normal item",
                orderDelivered: "Order delivered",
                receiptDelivered: "Receipt delivered",
                type: "type",
                status: "status",
                ItemDetailsAndStatus: "Item details / status",
                description: "description",
                typeAnythingYouNeed: "Type anything you need",
                paid: "paid",
                isPaid: "Is paid",
                total: "Total",
                editItem: "Edit item",
                pickup: "Pickup",
                suspended: "Suspended",
                processing: "Processing",
                rejected: "Rejected",
                delivered: "Delivered",
                ordersList: "Orders List",
                showOlderOrders: "Show older orders",
                showCanceledOrders: "Show canceled orders",
                showDeliveredOrders: "Show delivered orders",
                showPaidOrders: "Show paid orders",
                listOfOrders: "List of orders",
                addNewOrder: "Add new order",
                ordering: "Ordering",
                receiptsList: "Receipts list",
                showOlderReceipts: "Show older receipts",
                showCanceledReceipts: "Show canceled receipts",
                showDeliveredReceipts: "Show delivered receipts",
                showPaidReceipts: "Show paid receipts",
                listOfReceipts: "List of receipts",
                addNewReceipt: "Add new receipt",
                creatingNewReceipt: "Creating new receipt",
                Layout: "Layout",
                gridView: "Grid view",
                listView: "List view",
                Filters: "Filters",
                availableItems: "Available Items",
                searchByItemName: "Search by item name",
                allCategories: "All Categories",
                noCategories: "No Categories",
                vendor: "vendor",
                chooseVendor: "Choose vendor",
                chooseAVendor: "Choose a Vendor",
                chooseYourVendor: "Choose your vendor",
                firstYouNeedToChooseOneOfTheVendors: "First you need to choose one of the vendors",
                selectOneOfTheAvailableVendorsToCreateNewOrder: "To create a new order, select one of the available vendors",
                selectOneOfTheAvailableVendorsToCreateNewReceipt: "To create a new receipt, select one of the available vendors",
                seller: "seller",
                allSellers: "All sellers",
                chooseSeller: "Choose seller",
                chooseASeller: "Choose a Seller",
                chooseYourSeller: "Choose your seller",
                firstYouNeedToChooseOneOfTheSellers: "First you need to choose one of the sellers",
                selectOneOfTheAvailableSellersToCreateNewOrder: "To create a new order, select one of the available sellers",
                selectOneOfTheAvailableSellersToCreateNewReceipt: "To create a new receipt, select one of the available sellers",
                addItem: "Add Item",
                areYouSureYouWantToConvert: "Are you sure you want to convert?",
                areYouWantDeleteThisItem: "Are you sure you want to delete this item?",
                areYouWantCancelOrder: "Are you sure you want to cancel this order?",
                areYouWantCancelReceipt: "Are you sure you want to cancel this receipt?",
                getEmailLabel: "Please enter your email",
                enterValidEmailErr: "Please enter a valid email.",
                sendEmail: "Send email",
                no: "No",
                yes: "Yes",
                cancel: "Cancel",
                close: "Close",
                back: "Back",
                next: "Next",
                preview: "Preview",
                saveChanges: "Save changes",
                confirm: "Confirm",
                unselectAll: "Unselect all",
                selectAll: "Select all",
                selected: "selected",
                reset: "Reset",
                clear: "Clear",
                addToOrders: "add to orders",
                cancelThisOrder: "Cancel this order",
                addToReceipts: "Add to receipts",
                cancelReceipt: "Cancel receipt",
                cancelThisReceipt: "Cancel this receipt",
                // in the text below, the number '1' represents the section number (starting from 0) in the main jsx tag
                receiptModeChangedInJSX: "The desired receipt mode was changed to demo with the title '<1>Receipt {{targetOrder_id}}</1>'.",
                demo: "Demo",
                normal: "Normal",
                // showReceiptInDemoMode: "Show receipt in Demo Mode",
                // showReceiptInNormalMode: "Show receipt in Normal Mode",
                showReceiptInTargetMode: "Show receipt in {{targetMode}} Mode",
                showReceipt: "Show receipt",
                yesDeleteIt: "Yes, Delete it",
                pay: "Pay",
                payment: "Payment",
                payments: "Payments",
                payReport: "Pay report",
                paymentOf: "Payment of",
                checkoutOperation: "Checkout operation",
                itemsToPay: "Items to pay",
                price: "Price",
                basicPrice: "Basic price",
                totalPrice: "Total price",
                baseAmount: "Base amount",
                paidPrice: "Paid price",
                paidBy: "Paid by",
                discount: "Discount",
                discountAmount: "Discount amount",
                tipPrice: "Tip price",
                totalWithTip: "Total with tip",
                // discountedPayments: "Discounted payments",
                // paymentsWithoutDiscount: "Payments without Discount",
                paymentsIncludingDiscounts: "Payments including discounts",
                paymentsExcludingDiscounts: "Payments excluding discounts",
                paymentsTakingIntoAccountTheirDiscount: "Payments taking into account their discount",
                paymentsRegardlessOfTheirDiscount: "Payments regardless of their discount",
                openPaymentsStatistics: "Statistics of open payments",
                discountCannotBeLessThanZero: 'The amount of discount cannot be less than "0"',
                discountCannotBeNegative: "The discount amount cannot be negative",
                discountCannotBeMoreThanHundredPercent: 'The amount of discount cannot be more than "100%"',
                discountCannotBeMoreThan: 'The discount amount cannot be more than {{price}}',
                discountCannotBeMoreThanBaseAmount: 'The discount amount cannot be more than the "Base Amount"',
                theValueMustBeMoreThan: "The value must be more than {{price}}",
                theValueMustBeMoreThanBaseAmount: "The value must be more than Base Amount",
                tip: "Tip",
                tipAmount: "Tip amount",
                payByCreditCard: "Pay by Credit Card",
                payInCash: "Pay in Cash",
                transaction: "Transaction",
                noTransactionHasBeenMade: "No transaction has been made",
                backToTransactionsList: "Back to transactions list",
                transactionsHistory: "Transactions history",
                transactionDetails: "Transaction details",
                transactionId: "Transaction Id",
                transactionHasBeenSentToYourEmail: "The transaction has been sent to your email",
                paymentType: "Payment type",
                paymentStatus: "Payment status",
                ordersPrice: "Orders price",
                totalAmount: "Total amount",
                updatedAt: "Updated at",
                fromTime: "From time",
                toTime: "To time",
                backToOrders: "Back to orders",
                backToReceipts: "Back to receipts",
                closeAndBackToReceipts: "Close and back to receipts",
                showDetails: "Show details",
                receiptDetailsPage: "Receipt details page",
                vat: "VAT",
                net: "Net",
                tax: "Tax",
                gross: "Gross",
                sendingEmailFailed: "Sending email failed!",
                paymentFailed: "Payment failed",
                openPayments: "Open payments",
                Open_Payments: "Open-Payments",
                openPaymentsReport: "Open payments report",
                report: "Report",
                openPaymentStatistics: "Open payment statistics",
                allReceiptItemsHaveBeenPaid: "All items on this receipt have been paid",
                noItemsHaveBeenAddedInThisReceipt: "No items have been added in this receipt",
                thisItemHasBeenPaidThroughPOSAndIsAwaitingPaymentConfirmation: "This item has been paid through POS and is awaiting payment confirmation",
                yourReceiptHasBeenPaidThroughPOSAndIsAwaitingPaymentConfirmation: "Your Receipt has been paid through POS and is awaiting payment confirmation",
            }
        },
        de: {
            translation: {
                login: "Anmeldung",
                logout: "Ausloggen",
                digiPosLogin: "DigiAirPOS-Anmeldung",
                digiPosCashBoxLogin: "DigiPOS-Kassen-Login",
                username: "Nutzername",
                password: "Passwort",
                pinCode: "Geheimzahl",
                yourAuthenticationFailed: "Ihre Authentifizierung ist fehlgeschlagen",
                addDAPToHomeScreen: "Fügen Sie DigiPOS zu Ihrem Startbildschirm hinzu",
                addToHomeScreen: "Zum Startbildschirm hinzufügen",
                installDAP: "Installieren Sie DAP",
                language: "Sprache",
                chooseLanguage: "Sprache wählen",
                pageNotFound: "Seite nicht gefunden",
                backToDashboard: "Zurück zum Dashboard",
                transferToDemo: "Zur Demo übertragen",
                transferToLive: "Ins Leben übertragen",
                order: "Bestellung",
                orders: "Bestellungen",
                receipt: "Quittung",
                receipts: "Quittungen",
                option: "Möglichkeit",
                options: "Optionen",
                itemOptions: "Artikeloptionen",
                empty: "Leer",
                row: "Reihe",
                count: "die Zählung",
                step: "Schritt",
                title: "Titel",
                createdAt: "Hergestellt in",
                free: "Frei",
                freeItems: "kostenlose Artikel",
                items: "Artikel",
                item: "Artikel",
                minimum: "Mindest",
                maximum: "Maximal",
                min: "min",
                max: "max",
                all: "Alle",
                email: "Email",
                print: "Drucken",
                orderDetails: "Bestelldetails",
                noOrders: "Keine Befehle",
                noOrdersAdded: "Keine Bestellungen hinzugefügt",
                receiptDetails: "Empfangsdetails",
                noReceipts: "Keine Quittungen",
                noReceiptsAdded: "Keine Quittungen hinzugefügt",
                noAddedItems: "Es wurden keine Artikel hinzugefügt",
                createOrSelectOrderFirst: "Erstellen/wählen Sie zunächst eine Bestellung",
                createOrSelectReceiptFirst: "Erstellen/wählen Sie zunächst eine Quittung aus",
                customItem: "Benutzerdefinierter Artikel",
                customItems: "Benutzerdefinierte Artikel",
                normalItem: "normaler Artikel",
                orderDelivered: "Bestellung geliefert",
                receiptDelivered: "Quittung zugestellt",
                type: "Typ",
                status: "Status",
                ItemDetailsAndStatus: "Artikeldetails / Status",
                description: "Beschreibung",
                typeAnythingYouNeed: "Geben Sie alles ein, was Sie brauchen",
                paid: "bezahlt",
                isPaid: "Ist bezahlt",
                total: "Gesamt",
                editItem: "Element bearbeiten",
                // pickup: "Pickup",
                suspended: "Ausgesetzt",
                processing: "wird bearbeitet",
                rejected: "Abgelehnt",
                delivered: "Geliefert",
                ordersList: "Auftragsliste",
                showOlderOrders: "Ältere Bestellungen anzeigen",
                showCanceledOrders: "Stornierte Bestellungen anzeigen",
                showDeliveredOrders: "Ausgelieferte Bestellungen anzeigen",
                showPaidOrders: "Bezahlte Bestellungen anzeigen",
                listOfOrders: "Liste der Bestellungen",
                addNewOrder: "Neue Bestellung hinzufügen",
                ordering: "Bestellung",
                receiptsList: "Quittungsliste",
                showOlderReceipts: "Ältere Belege anzeigen",
                showCanceledReceipts: "Stornierte Belege anzeigen",
                showDeliveredReceipts: "Zugestellte Quittungen anzeigen",
                showPaidReceipts: "Bezahlte Quittungen anzeigen",
                listOfReceipts: "Liste der Quittungen",
                addNewReceipt: "Neue Quittung hinzufügen",
                creatingNewReceipt: "Neue Quittung erstellen",
                Layout: "Layout",
                gridView: "Rasteransicht",
                listView: "Listenansicht",
                Filters: "Filter",
                availableItems: "Verfügbare Artikel",
                searchByItemName: "Suche nach Artikelnamen",
                allCategories: "Alle Kategorien",
                noCategories: "Keine Kategorien",
                vendor: "Verkäufer",
                chooseVendor: "Wählen Sie den Verkäufer",
                chooseAVendor: "Wählen Sie einen Anbieter",
                chooseYourVendor: "Wählen Sie Ihren Verkäufer",
                firstYouNeedToChooseOneOfTheVendors: "Zuerst müssen Sie einen der Anbieter auswählen",
                selectOneOfTheAvailableVendorsToCreateNewOrder: "Um eine neue Bestellung zu erstellen, wählen Sie einen der verfügbaren Lieferanten aus",
                selectOneOfTheAvailableVendorsToCreateNewReceipt: "Um eine neue Quittung zu erstellen, wählen Sie einen der verfügbaren Lieferanten aus",
                seller: "Verkäufer",
                allSellers: "Alle Verkäufer",
                chooseSeller: "Wählen Sie den Verkäufer",
                chooseASeller: "Wählen Sie einen Verkäufer",
                chooseYourSeller: "Wählen Sie Ihren Verkäufer",
                firstYouNeedToChooseOneOfTheSellers: "Zuerst müssen Sie einen der Verkäufer auswählen",
                selectOneOfTheAvailableSellersToCreateNewOrder: "Um eine neue Bestellung zu erstellen, wählen Sie einen der verfügbaren Verkäufer aus",
                selectOneOfTheAvailableSellersToCreateNewReceipt: "Um eine neue Quittung zu erstellen, wählen Sie einen der verfügbaren Verkäufer aus",
                addItem: "Artikel hinzufügen",
                areYouSureYouWantToConvert: "Sind Sie sicher, dass Sie konvertieren möchten?",
                areYouWantDeleteThisItem: "Sind Sie sicher, dass Sie dieses Element löschen möchten ?",
                areYouWantCancelOrder: "Sind Sie sicher, dass Sie diese Bestellung stornieren möchten ?",
                areYouWantCancelReceipt: "Sind Sie sicher, dass Sie diesen Beleg stornieren möchten?",
                getEmailLabel: "Bitte geben Sie ihre E-Mail-Adresse ein",
                enterValidEmailErr: "Bitte geben Sie eine gültige Email-Adresse ein.",
                sendEmail: "E-Mail senden",
                no: "Nein",
                yes: "Ja",
                cancel: "Stornieren",
                close: "Schließen",
                back: "zurück",
                next: "Nächstes",
                preview: "Vorschau",
                saveChanges: "Änderungen speichern",
                confirm: "Bestätigen",
                unselectAll: "Alles wiederufen",
                selectAll: "Wählen Sie Alle",
                selected: "ausgewählt",
                reset: "Zurücksetzen",
                clear: "Klar",
                addToOrders: "zu Bestellungen hinzufügen",
                cancelThisOrder: "Stornieren Sie diese Bestellung",
                addToReceipts: "Zu Quittungen hinzufügen",
                cancelReceipt: "Empfang stornieren",
                cancelThisReceipt: "Stornieren Sie diesen Beleg",
                // in the text below, the number '1' represents the section number (starting from 0) in the main jsx tag
                receiptModeChangedInJSX: "Der gewünschte Beleg Modus wurde auf Demo mit dem Titel '<1>Beleg {{targetOrder_id}}</1>' geändert.",
                demo: "Demo",
                normal: "Normal",
                // showReceiptInDemoMode: "Quittung im Demo Modus anzeigen",
                // showReceiptInNormalMode: "Quittung im Normal Modus anzeigen",
                showReceiptInTargetMode: "Quittung im {{targetMode}} Modus anzeigen",
                showReceipt: "Quittung vorzeigen",
                yesDeleteIt: "Ja, löschen Sie es",
                pay: "Zahlen",
                payment: "Zahlung",
                payments: "Zahlungen",
                payReport: "Gehaltsbericht",
                paymentOf: "Bezahlung der",
                checkoutOperation: "Kassenbetrieb",
                itemsToPay: "Zu bezahlende Artikel",
                price: "Preis",
                basicPrice: "Grundpreis",
                totalPrice: "Gesamtpreis",
                baseAmount: "Grundbetrag",
                paidPrice: "Bezahlter Preis",
                paidBy: "Bezahlt von",
                discount: "Rabatt",
                discountAmount: "Rabattbetrag",
                tipPrice: "Trinkgeldpreis",
                totalWithTip: "Total mit Trinkgeld",
                // discountedPayments: "Ermäßigte Zahlungen",
                // paymentsWithoutDiscount: "Zahlungen ohne Skonto",
                paymentsIncludingDiscounts: "Payments including discounts",
                paymentsExcludingDiscounts: "Zahlungen ohne Skonti",
                paymentsTakingIntoAccountTheirDiscount: "Zahlungen unter Berücksichtigung ihres Skontos",
                paymentsRegardlessOfTheirDiscount: "Zahlungen unabhängig von ihrem Rabatt",
                openPaymentsStatistics: "Statistik offener Zahlungen",
                discountCannotBeLessThanZero: 'Der Rabattbetrag darf nicht kleiner als "0" sein.',
                discountCannotBeNegative: "Der Rabattbetrag darf nicht negativ sein",
                discountCannotBeMoreThanHundredPercent: 'Der Rabattbetrag darf nicht mehr als "100%" betragen.',
                discountCannotBeMoreThan: 'Der Rabattbetrag darf nicht mehr als {{price}} betragen',
                discountCannotBeMoreThanBaseAmount: 'Der Rabattbetrag darf nicht höher sein als der "Grundbetrag".',
                theValueMustBeMoreThan: "Der Wert muss mehr als {{price}} betragen",
                theValueMustBeMoreThanBaseAmount: "Der Wert muss größer als der Basisbetrag sein",
                tip: "Trinkgeldbetrag",
                tipAmount: "Trinkgeldbetrag",
                payByCreditCard: "Mit Kreditkarte bezahlen",
                payInCash: "Bar bezahlen",
                transaction: "Transaktion",
                noTransactionHasBeenMade: "Es wurde keine Transaktion durchgeführt",
                backToTransactionsList: "zurück zur Transaktionsliste",
                transactionsHistory: "Transaktionsverlauf",
                transactionDetails: "Transaktionsdetails",
                transactionId: "Transaktions-ID",
                transactionHasBeenSentToYourEmail: "Die Transaktion wurde an Ihre E-Mail-Adresse gesendet",
                paymentType: "Zahlungsart",
                paymentStatus: "Zahlungsstatus",
                ordersPrice: "Bestellpreis",
                totalAmount: "Gesamtmenge",
                updatedAt: "aktualisiert am",
                fromTime: "Von Zeit",
                toTime: "Zur Zeit",
                backToOrders: "Zurück zu Bestellungen",
                backToReceipts: "Zurück zu den Quittungen",
                closeAndBackToReceipts: "Schließen und zurück zu den Quittungen",
                showDetails: "zeige Details",
                receiptDetailsPage: "Seite mit Empfangsdetails",
                vat: "MwSt",
                net: "Netto",
                tax: "Steuer",
                gross: "Brutto",
                sendingEmailFailed: "E-Mail-Versand fehlgeschlagen!",
                paymentFailed: "Bezahlung fehlgeschlagen",
                openPayments: "Offene Zahlungen",
                Open_Payments: "Offene Zahlungen",
                openPaymentsReport: "Offener Zahlungsbericht",
                report: "Bericht",
                openPaymentStatistics: "Zahlungsstatistik öffnen",
                allReceiptItemsHaveBeenPaid: "Alle Posten auf dieser Quittung wurden bezahlt",
                noItemsHaveBeenAddedInThisReceipt: "Dieser Quittung wurden keine Artikel hinzugefügt",
                thisItemHasBeenPaidThroughPOSAndIsAwaitingPaymentConfirmation: "Dieser Artikel wurde über POS bezahlt und wartet auf die Zahlungsbestätigung",
                yourReceiptHasBeenPaidThroughPOSAndIsAwaitingPaymentConfirmation: "Ihre Quittung wurde über POS bezahlt und wartet auf die Zahlungsbestätigung",
            }
        },
    }
  });

export default i18n;