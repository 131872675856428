import { createSlice } from "@reduxjs/toolkit";
import allStepsInfo from "../../components/Dashboard/stepsBox/allStepsInfo";


const stepManagementSlice = createSlice({
    name: 'stepManagement',
    initialState: {
        currentStepId: allStepsInfo[0]?.id,
        stepsData: [allStepsInfo[0]],
        showTheNoSelectedSellerError: false,
        currentOrderId: null,
        currentOrderDetails: null,
        currentOrderDetailsErrorText: '',
        ordersList: null,
        olderOrdersList: [],
        ordersListForPreview: null,
        totalPriceOfOrderAddedItems: 0,
        ordersListItemsAreLoading: false,
        olderOrdersListItemsAreLoading: false,
        loadingForCreatingNewOrder: false,
        addedOrdersBoxIsLoading: false,
        availableItemsAreLoading: false,
        availableItemsList: null,
        selectedCategoryId: '',
        itemPreviewBoxIsLoading: false,
        previewItemDetails: null,
        previewCustomItemStepsData: null,
        editingCustomItemType: undefined,
        initialItemCustomizationForEditing: null,
        previewItemDataForEditing: null,
        currency_symbol: '',
        refreshDashboardDataFlag: undefined,
        refreshOrdersListBoxFlag: undefined,
        showOlderOrders: false,
        showCanceledOrders: false,
        // showDeliveredOrders: true,
        showPaidOrders: true,
        showSellerSelectionModal: false,
        showTransactionsHistoryModal: false,
    },
    reducers: {
        selectStep: (state, action) => {
            const {
                targetStepId = state.currentStepId,
                orderId = state.currentOrderId,
                currentOrderDetails = state.currentOrderDetails,
                errorText = "",
                availableItemsList = state.availableItemsList,
                previewItemDetails = state.previewItemDetails,
                previewCustomItemStepsData = state.previewCustomItemStepsData,
            } = action.payload || {};

            const targetStepData = allStepsInfo.find(stp => stp.id === targetStepId);
            const targetStepIndex = allStepsInfo.indexOf(targetStepData);
            const new_stepsData = allStepsInfo.filter((stp, index) => {
                return index <= targetStepIndex
            });

            state.currentStepId = targetStepData?.id || "";
            state.currentOrderId = orderId;
            state.currentOrderDetails = currentOrderDetails;
            state.currentOrderDetailsErrorText = errorText;
            state.availableItemsList = availableItemsList;
            state.previewItemDetails = previewItemDetails;
            state.previewCustomItemStepsData = previewCustomItemStepsData;
            state.stepsData = new_stepsData;
            if (availableItemsList) {
                state.availableItemsAreLoading = false;
            }
            if (previewItemDetails || previewCustomItemStepsData) {
                state.itemPreviewBoxIsLoading = false;
            }
        },
        toggleShowSellerSelectionModal: (state, action) => {
            const {
                show = !state.showSellerSelectionModal,
            } = action.payload;

            state.showSellerSelectionModal = show;
        },
        // toggleShowTheNoSelectedSellerError: (state, action) => {
        //     const {
        //         show = !state.showTheNoSelectedSellerError,
        //     } = action.payload;

        //     state.showTheNoSelectedSellerError = show;
        // },
        setOrdersListData: (state, action) => {
            const {
                ordersList = [],
                currentOrderId,
            } = action.payload || {};

            state.ordersList = ordersList;

            if (currentOrderId !== undefined) {
                state.currentOrderId = currentOrderId;
                state.currentOrderDetails = ordersList?.find(ord => ord.id === currentOrderId) || {};
                state.currentOrderDetailsErrorText = "";
            }

            state.ordersListItemsAreLoading = false;
        },
        setOlderOrdersListData: (state, action) => {
            let {
                olderOrdersList = [],
            } = action.payload || {};

            olderOrdersList = olderOrdersList?.filter(oldOrder => {
                const existingItemInItems = state.ordersList?.find(ord => ord.id === oldOrder.id);
                return !existingItemInItems;
            });

            state.olderOrdersList = olderOrdersList || [];

            state.olderOrdersListItemsAreLoading = false;
        },
        changeFiltersAppliedToOrdersList: (state, action) => {
            const {
                showCanceledOrders = state.showCanceledOrders,
                showPaidOrders = state.showPaidOrders,
            } = action.payload;

            let allOrderItems = state.ordersList;

            if (state.showOlderOrders) {
                allOrderItems = allOrderItems?.concat(state.olderOrdersList)
            }

            const ordersListForPreview = allOrderItems?.filter(ord => {
                let filterThisItem;

                if (!showCanceledOrders) {
                    filterThisItem = ord.order_status_key === "rejected";
                }

                if (!filterThisItem && !showPaidOrders) {
                    // filterThisItem = ord.order_status_key === "delivered";
                    filterThisItem = ord.payment_status === "paid";
                }

                return !filterThisItem;
            });

            state.showCanceledOrders = showCanceledOrders;
            state.showPaidOrders = showPaidOrders;

            state.ordersListForPreview = ordersListForPreview;
        },
        setSelectedCategoryId: (state, action) => {
            const {
                selectedCategoryId = "",
            } = action.payload || {};

            state.selectedCategoryId = selectedCategoryId;
        },
        selectOrder: (state, action) => {
            const {
                orderId = null,
            } = action.payload || {};

            state.currentOrderId = orderId;
            state.currentOrderDetails = state.ordersList?.find(ord => ord.id === orderId) || {};
            state.currentOrderDetailsErrorText = "";
        },
        changeCurrentOrderData: (state, action) => {
            const {
                currentOrderDetails = state.currentOrderDetails,
                errorText = "",
            } = action.payload || {};

            state.currentOrderId = currentOrderDetails?.id;
            state.currentOrderDetails = currentOrderDetails;
            state.currentOrderDetailsErrorText = errorText;
        },
        changeTotalPriceOfOrderAddedItems: (state, action) => {
            const {
                totalPrice,
            } = action.payload || {};

            if (totalPrice === undefined) {
                return;
            }

            state.totalPriceOfOrderAddedItems = totalPrice;
        },
        changeCurrentEditingData: (state, action) => {
            const {
                editingCustomItemType = state.editingCustomItemType,
                initialItemCustomizationForEditing = state.initialItemCustomizationForEditing,
                previewItemDataForEditing = state.previewItemDataForEditing,
            } = action.payload || {};

            state.editingCustomItemType = editingCustomItemType;
            state.initialItemCustomizationForEditing = initialItemCustomizationForEditing;
            state.previewItemDataForEditing = previewItemDataForEditing;

            if (previewItemDataForEditing) {
                state.itemPreviewBoxIsLoading = false;
            }
        },
        toggleTransactionsHistoryModal: (state, action) => {
            const {
                showModal = !state.showTransactionsHistoryModal,
            } = action.payload;

            state.showTransactionsHistoryModal = showModal;
        },
        refreshDashboardData: (state, action) => {
            state.refreshDashboardDataFlag = !state.refreshDashboardDataFlag;
        },
        refreshOrdersListBox: (state, action) => {
            state.refreshOrdersListBoxFlag = !state.refreshOrdersListBoxFlag;
        },
        toggleShowOlderOrders: (state, action) => {
            const {
                showOlderOrders = !state.showOlderOrders,
            } = action.payload;

            state.showOlderOrders = showOlderOrders;
        },
        toggleLoaderForAddedOrdersBox: (state, action) => {
            const {
                isLoading,
            } = action.payload || {};

            if (isLoading !== undefined) {
                state.addedOrdersBoxIsLoading = !!isLoading;
            }
        },
        activateLoaderForOrdersList: (state, action) => {
            state.ordersListItemsAreLoading = true;
        },
        activateLoaderForOlderOrdersList: (state, action) => {
            state.olderOrdersListItemsAreLoading = true;
        },
        toggleLoaderForCreatingNewOrder: (state, action) => {
            const {
                isLoading = !state.loadingForCreatingNewOrder,
            } = action.payload || {};

            state.loadingForCreatingNewOrder = isLoading;
        },
        activateLoaderForAvailableItemsList: (state, action) => {
            state.availableItemsAreLoading = true;
        },
        activateLoaderForItemPreviewBox: (state, action) => {
            state.itemPreviewBoxIsLoading = true;
        },
    }
});


export const {
    selectStep,
    toggleShowSellerSelectionModal,
    // toggleShowTheNoSelectedSellerError,
    setOrdersListData,
    setOlderOrdersListData,
    changeFiltersAppliedToOrdersList,
    setSelectedCategoryId,
    selectOrder,
    changeCurrentOrderData,
    changeTotalPriceOfOrderAddedItems,
    changeCurrentEditingData,
    toggleTransactionsHistoryModal,
    refreshDashboardData,
    refreshOrdersListBox,
    toggleShowOlderOrders,
    toggleLoaderForAddedOrdersBox,
    activateLoaderForOrdersList,
    activateLoaderForOlderOrdersList,
    toggleLoaderForCreatingNewOrder,
    activateLoaderForAvailableItemsList,
    activateLoaderForItemPreviewBox,
} = stepManagementSlice.actions;

export default stepManagementSlice.reducer;
